@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
}

.address__container {
  position: fixed;
  width: 22rem;
  height: 14rem;
  background: white;
  border: solid 1px #babbbb;
  left: 50%;
  transform: translate(-50%, -50%);
}

.outcome__container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #babbbb;

}

.outcome,
.outcome__message,
.label {
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
}

.outcome {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  flex: 1;
}

.outcome__message {
  word-spacing: 2px;
  margin-top: 25px;
}

.label {
  margin-top: 50px;
  margin-bottom: 8px;
}



.wallet__form {
  position: relative;
  text-align: center;
}

.wallet__form-input {
  margin: 0 auto;
  width: 12rem;
  margin: 0 auto;
  border: 1px solid #babbbb;
  padding: .5rem;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #202020;
  background: none;
  outline: none;
}

.close {
  cursor: pointer;
}

.closed {
  display: none;
}