  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


  /*=============== BASE ===============*/
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html {
    height: 100%;
  }

  html::-webkit-scrollbar {
    width: 12px;
  }

  html::-webkit-scrollbar-thumb {
    border-radius: 25px;
    background: rgb(117, 117, 117);
  }

  html::-webkit-scrollbar-thumb:hover {
    background: #5a5a5a;
  }

  html {
    scrollbar-width: thin;
  }


  body {
    font-family: 'Roboto', sans-serif;
    background-color: #faf8de;
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
  }

  .top__level {
    min-height: 100vh;
  }



  /*=============== LOGO ===============*/

  .logo__container {
    display: flex;
    padding: 5px 20px;
    padding-inline: 1.5rem;
    padding-bottom: 5rem;
    height: 250px;
  }

  .logo div {
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .logo img {
    width: 6.5rem;
    margin-right: .5rem;
  }

  /*=============== FIND SOMEBODY ===============*/
  .message {
    position: relative;
    width: 100%;
    color: #202020;
    font-size: 12px;
    font-weight: bold;
    padding: 5px;
    margin: 0;
    transform: translate(-50%, -50%);
    text-align: center;
    left: 50%;
    text-transform: uppercase;
    border: none;
  }

  /*=============== PASSWORD ENTRY ===============*/

  .password__form {
    position: relative;
    text-align: center;
  }

  .password__form-div {
    position: relative;
    height: 2rem;
  }

  .passwordButton {
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
    vertical-align:sub;
    
  }

  .password__form-input {
    margin: 0 auto;
    width: 12rem;
    margin: 0 auto;
    height: 100%;
    border: 2px solid #202020;
    border-radius: 7px;
    padding: 1.5rem;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #202020;
    background: none;
    outline: none;
  }

  /*=============== LEGS IMAGE ===============*/

  .legs {
    position: relative;
    width: 18rem;
    margin-left: -11rem;
    transform: scaleX(-1) rotate(112deg);
  }

  @media screen and (min-width: 952px) {

    .legs__container {
      position: relative;

    }

    .legs {
      transform: scaleX(-1) rotate(170deg);
      position: fixed;
      bottom: -11rem;
      left: 8rem;
    }
  }

  /*=============== FOOTER===============*/

  .footer {
    width: 100%;
    margin-bottom: 5px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    word-spacing: 2px;
    display: flex;
    justify-content: space-between;
    border-top: solid #585858 2px;
  }


  .confidential {
    border-right: solid 1px #202020;
    margin-right: 5px;
  }

  .date {
    margin-left: 2px;
  }

  .footer__text {
    color: #585858;
    padding-top: 1rem;
    padding-bottom: 5px;
    padding-left: 10px;
  }

  .timer-speaker__container {
    display: flex;
    align-items: center;

  }


  .speaker {
    width: 3rem;
    height: 2rem;
    margin-top: 10px;
    margin-right: 19px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  /*=============== FOOTER DESKTOP ===============*/


  @media screen and (min-width: 952px) {

    .footer {
      display: flex;
      writing-mode: vertical-rl;
      transform: scaleY(-1);
      width: 5rem;
      height: 100%;
      position: absolute;
      text-align: center;
      margin-left: 94%;
      border-left: solid #585858 2px;
      border-top: none;
    }

    .footer__text {
      color: #585858;
      padding-top: 1rem;
      padding-bottom: 5px;
      padding-left: 10px;
      transform: scaleX(-1);
      margin-left: 25px;
    }

    .confidential {
      border-bottom: solid 1px #202020;
      padding-bottom: 5px;
      border-right: none;
      margin-right: 5px;
    }

    .date {
      margin-bottom: 10px;
    }

    @-moz-document url-prefix() {
      .date {
        margin-left: -4px;
      }
    }

    .timer-speaker__container {
      writing-mode: horizontal-tb;
      transform: scaleY(-1);
      display: inline-block;
      padding-top: 15px;
    }

    .speaker {
      margin-top: 10px;
      width: 2rem;
      height: 2rem;
      margin-right: -10px;
      margin-bottom: 10px;
    }

    span {
      display: inline-block;
    }
  }

  /*=============== IPAD STUFF===============*/

  @media all and (device-width: 810px) and (device-height: 1080px) and (orientation:portrait) {
    .legs {
      bottom: -9rem;
    }

    .speaker {
      margin-right: 30px;
    }
  }