.body {
    font-family: 'Roboto', sans-serif;
    background-color: #faf8de;
    position: relative;
    min-height: 100%;
    overflow-x: hidden;
  }
  .container {
    height: '100%';
    margin-top: 12vb;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo{
    height: 50vh;
    width: 39vh;
  }